import React,{ useContext, useState } from 'react'
import { BiUser } from 'react-icons/bi'
import { UserContext } from '../../../contexts/userContext'
import logo from '../../../assets/images/VOX LOGO Horizintal.png'


const EsqueciSenha = () => {
    const { esqueciSenha } = useContext(UserContext);
    const [login, setLogin] = useState({email: ''});

    function backToLogin() {
        window.location.href = '/login';
    }

    function handleEmailChange(event) {
        setLogin({...login, email: event.target.value})
    }

    function sendEmail() {
        esqueciSenha(login.email);
    }

  return (
    <div className='login-wrapper'>
        <div className='login-container'>
            <div className='login-header'>
                <img className='logo' src={logo} alt='logo' />
            </div>
            <div className='login-form'>
                <p className='login-text'>Esqueceu sua senha?</p>
                <div className="login-input-content">
                    <input className='login-input' type='text' placeholder='Escreva seu e-email' onChange={handleEmailChange}/>
                    <BiUser className='login-icon' />
                </div>

                <button className='login-button' onClick={sendEmail} >Enviar</button>
                <button className='login-button' onClick={backToLogin} style={{marginBottom:"20px"}}>Voltar</button>
            </div>
        </div>
    </div>
  )
}

export default EsqueciSenha
