import React from 'react'

const SelecLocal = ({ locais, handleLocalChange }) => {
  return (
    <div className="items-select">
      <label>Local:</label>
      <select className="select-agendamento__select" onChange={handleLocalChange}>
        {locais.length > 0 ? (
          <option value="0">Selecione um Local</option>
        ) : (
          <option value="0">Não há Locais disponíveis</option>
        )}
        {locais.map((local, index) => {
          return (
            <option key={index} value={local._id}>
              {local.nome}
            </option>
          );
        })}
      </select>
    </div>
  )
}

export default SelecLocal
