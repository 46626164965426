import React,{ useState, useEffect,useContext } from "react";
import { AgendaContext } from "../../../../../contexts/agendaContext";
import { PrefeituraContext } from "../../../../../contexts/prefeituraContext";
import "../../calendarStyle.css";

const DesmarcarAgendamento = (props) => {
    const { acharEspecialidade,acharLocal } = useContext(PrefeituraContext);
    const { dadosAgendamento, desmarcarHorario , horarioSelecionado} = useContext(AgendaContext)
    const [agendamento, setAgendamento] = useState({})
    const [data, setData] = useState({})
    
    useEffect(() => {
      let newDados = dadosAgendamento ? {...dadosAgendamento} : {}
      setAgendamento(newDados)
    }, [dadosAgendamento])
  
    useEffect(() => {
      let dia = horarioSelecionado ? {...horarioSelecionado} : {}
      setData(dia)
    }, [horarioSelecionado])
  
   
    const desmarcarAgendamento = () => {
      let dados = {...agendamento}
      dados = {...dados, ...data, profissional: data.profissional}
      desmarcarHorario(dados)
      props.removeMarkedDate(dados)
    }
  
    return (
      <>
          {props.showDesmarcarAgendamento && (
          <div
              className="bg-modal-agenda-dia"
              onClick={(e) => props.closeModalOnOutsideClick(e)}
          ></div>
          )}
          <div className="confirmar-card">
              <div className="confirmar-card-header">
                  <h2>Confirmar Desmarcar Agendamento</h2>
                  {agendamento && (
                    <div className="confirmar-card-info">
                      <p><b>Local:</b>{acharLocal(agendamento.local)}</p> 
                      <p><b>Especialidade:</b>{acharEspecialidade(agendamento.especialidade)}</p>
                    </div>
                  )}
              </div>
              <button className="confirmar-button" onClick={desmarcarAgendamento}>Confirmar</button>
          </div>
      </>
    )
}

export default DesmarcarAgendamento
