import React from 'react';
import './App.css';
import { UserProvider } from './contexts/userContext';
import Routeror from './routes/Routeror';
import Alert from './components/Alert';


function App() {
  return (
    <div className="App">
      <UserProvider>
        <Alert />
        <Routeror />
      </UserProvider>
    </div>
  );
}

export default App;
