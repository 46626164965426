import { createContext, useState, useContext, useCallback } from "react";
import apiUser from "../apiUser";
import { UserContext } from "./userContext";
import { UserConfigContext } from "./userConfigContext";


export const AgendaContext = createContext({});

export const AgendaProvider = ({ children }) => {
  const { alert, setAlert, } = useContext(UserContext);
  const { updateHistorico } = useContext(UserConfigContext);
  const [agenda, setAgenda] = useState({});
  const [datasDisponiveis, setDatasDisponiveis] = useState([]);
  const [horarioMarcado, setHorarioMarcado] = useState([]);
  const [agendas, setAgendas] = useState([]);
  const [dadosAgendamento, setDadosAgendamento] = useState({}); //[data,horario,profissional,prefeitura,especialidade]
  const [loadingDesmarcar, setLoadingDesmarcar] = useState(false);
  const [diaSelecionado, setDiaSelecionado] = useState("");
  const [horarioSelecionado, setHorarioSelecionado] = useState({});
  const [loadingConfirmar, setLoadingConfirmar] = useState(false);
  const [loading, setLoading] = useState(false);


  const receberAgendas = async (agenda) => {
    await apiUser
      .post("/agendamento/allAgendas", { ...agenda })
      .then((response) => {
        let newDatasMarcadas = [...response.data];
        setAgendas(newDatasMarcadas);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const receberDatasDisponives = async (agenda) => {
    setLoading(true);
    await apiUser
      .post("/agendamento/getAgenda", { ...agenda })
      .then((response) => {
        setDatasDisponiveis(response.data.agendas);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const marcarHorario = async (agenda) => {
    await apiUser
      .post("/agendamento/marcar", agenda, {
        headers: {
          "x-client-type": "web",
        },
      })
      .then((response) => {
        let newMessage = response.data.message;
        let newHistorico = response.data.historico;
        updateHistorico(newHistorico);
        if (newMessage) {
          setAlert({ visible: true, title: "Sucesso", placeholder: newMessage, type: "success" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const desmarcarHorario = useCallback(async (agenda) => {
    setLoadingDesmarcar(true);
    await apiUser
      .post("/agendamento/desmarcar", agenda)
      .then((response) => {
        let newMessage = response.data.message;
        let newHistorico = response.data.historico;
        setLoadingDesmarcar(false);
        if (newMessage) {
          setAlert({ visible: true, title: "Sucesso", placeholder: newMessage, type: "success" });
        }
        updateHistorico(newHistorico);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [updateHistorico])

  const confirmarAgendamento = async (hash,idPrefeitura) => {
    setLoadingConfirmar(true);
    await apiUser.get(`/accept?hash=${hash}&_id=${idPrefeitura}&userAgent=mobile`).then((response) => {
        let newMessage = response.data.message
        setLoadingConfirmar(false);
        setAlert({ visible: true, title: "Sucesso", placeholder: newMessage, type: "success"});
        updateHistorico(response.data.historico);
    }).catch((error) => {
        console.log(error)
    })
}


  const desmarcarHorariohistorico = async (agenda) => {
    setAlert({
      visible: true, title: "Confirmar", placeholder: "Você realmente deseja desmarcar seu horario?",
      type: "confirm", params: agenda, callback: desmarcarHorario
    });

  };

  const markAgenda = async (dataMarcada) => {
    let newAgendas = [...agendas];
    agendas.forEach((agenda) => {
      if (
        agenda.profissional === dataMarcada.profissional &&
        agenda.especialidade === dataMarcada.especialidade &&
        agenda.serviço === dataMarcada.serviço &&
        agenda.local === dataMarcada.local
      ) {
        agenda.datasMarcadas.push(
          {
            data: dataMarcada.data,
            usuario: dataMarcada.usuario,
          }
        )
      }
    })
    setAgendas(newAgendas);
  }

  const unMarkAgenda = async (dataMarcada) => {
    let newAgendas = [...agendas];
    agendas.forEach((agenda) => {
      if (
        agenda.profissional === dataMarcada.profissional &&
        agenda.especialidade === dataMarcada.especialidade &&
        agenda.serviço === dataMarcada.serviço &&
        agenda.local === dataMarcada.local
      ) {
        agenda.datasMarcadas = agenda.datasMarcadas.filter((data) => data.data !== dataMarcada.data)
      }
    })
    setAgendas(newAgendas);
  }
 

  const nomesDosMeses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const diasSemana = [
    { index: 0, nome: "Domingo" },
    { index: 1, nome: "Segunda" },
    { index: 2, nome: "Terça" },
    { index: 3, nome: "Quarta" },
    { index: 4, nome: "Quinta" },
    { index: 5, nome: "Sexta" },
    { index: 6, nome: "Sábado" },
  ];

  return (
    <AgendaContext.Provider
      value={{
        agenda,
        setAgenda,
        receberDatasDisponives,
        datasDisponiveis,
        marcarHorario,
        horarioMarcado,
        setHorarioMarcado,
        desmarcarHorario,
        receberAgendas,
        agendas,
        setDadosAgendamento,
        dadosAgendamento,
        loadingDesmarcar,
        loadingConfirmar,
        loading,
        markAgenda,
        nomesDosMeses,
        diasSemana,
        desmarcarHorariohistorico,
        setDiaSelecionado,
        diaSelecionado,
        setHorarioSelecionado,
        horarioSelecionado,
        unMarkAgenda,
        confirmarAgendamento
      }}
    >
      {children}
    </AgendaContext.Provider>
  );
};
