import React from 'react'
import { BiLike } from 'react-icons/bi'

export default function Success(props) {
    const {  alert } = props;
    return (
        <div className='background-modal'>
            <div className='alert-container'>
                <div className="alert-content">
                    <BiLike className="alert-icon" /> 
                    <span className='alert-span'> {alert.placeholder} </span>
                </div>
                <div className="alert-container-button" >
                    <button className={"alert-success-ok-button" } onClick={props.quitAlert}>Ok</button>
                </div>
            </div>
        </div>
    )
}
