import React,{ useContext, useEffect, useState } from 'react'
import { AgendaContext } from '../../../../contexts/agendaContext';
import  LoadingSpinner  from '../../../Loader/load';
import AgendaDia from '../AgendaDia/index'; 
import Calendar from "react-calendar";
import "../calendarStyle.css";

const Agendamento = (props) => {
  const { datasDisponiveis, agendas, loading,setDiaSelecionado } = useContext(AgendaContext);
  const [disponiveis, setDisponiveis] = useState([]);
  const [marcadas, setMarcadas] = useState([]);
  const [data, setData] = useState(new Date());
  const [markedDates, setMarkedDates] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    let newDatas = datasDisponiveis ? [...datasDisponiveis] : []
    setDisponiveis(newDatas)
  }, [datasDisponiveis])

  useEffect(() => {
    let newDatasMarcadas = agendas ? [...agendas] : []
    setMarcadas(newDatasMarcadas)
  }, [agendas])

  const onChange = (date) => {
    setData(date);
  };

  const tileClassName = ({ date }) => {
    const inputDate = date;
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth() + 1;
    const day = inputDate.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    let isMarked = []
    marcadas.forEach((data) => {
      isMarked = data.datasMarcadas.some(
        (data) => data.data.slice(0, 10) === formattedDate
      ); 
    })
    return isMarked ? "marked" : "";
  };
  
  const handleTileClick = (date) => {
    setDiaSelecionado(date);
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth() + 1 ;
    const day = inputDate.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    let disponivelDates= [];
    let newMarkedDates = [];
    
    marcadas.forEach((data) => {
      data.datasMarcadas.forEach((dataMarcada) => {
        if(dataMarcada.data.slice(0, 10) === formattedDate) {
          let profissional = data.profissional
          let newDate = {...dataMarcada, profissional}
          newMarkedDates.push(newDate)
        }
      })
    }) 
    disponiveis.forEach((item) => {
      if(item.data.slice(0, 10) === formattedDate) {
        disponivelDates.push(item)
      }
    })
    setMarkedDates(newMarkedDates);
    setAvailableDates(disponivelDates);
    setShowModal(true);
    props.setMostrarHistorico(false)
  };

  return (

    <>
      {loading === false ?
        <Calendar
          onChange={onChange}
          value={data}
          locale={"pt-BR"}
          tileClassName={tileClassName}
          onClickDay={handleTileClick}
        /> : <LoadingSpinner/>
      }

      {showModal && (
        <AgendaDia mostrarHistorico={props.setMostrarHistorico} setShowModal={setShowModal} showModal={showModal}
         marcadas={markedDates} setMarcadas={setMarkedDates} disponiveis={availableDates} 
         setDisponiveis={setAvailableDates} handleTileClick={handleTileClick} />
      )}

    </>

  )
}

export default Agendamento