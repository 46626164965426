import { Route, Routes } from "react-router-dom";
import Login from '../components/Login/index.jsx';
import EsqueciSenha from "../components/Login/Actions/esqueciSenha.jsx";
import InserirSenhaNova from "../components/Login/Actions/inserirSenhaNova.jsx";
import Cadastro from "../components/Cadastro/index.jsx";
import Authenticator from "./Authenticator.jsx";
import Home from '../components/Home/index.jsx';
import Termos from '../components/Termos/index.jsx';


export default function Routeror(props) {
  return (

    <Routes>
      <Route path="/" element={<Authenticator />} />
      <Route path="/login" element={<Login />} />
      <Route path="/esqueciSenha" element={<EsqueciSenha />} />
      <Route path="/cadastro" element={<Cadastro />} />     
      <Route path="/home" element={<Authenticator><Home /></Authenticator>} />
      <Route path="/inserirSenhaNova/:hashConfirmacao" element={<InserirSenhaNova />} />
      <Route path="/termos" element={<Termos />} />
    </Routes>

  );
}