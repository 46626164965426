import env from "react-dotenv";
import axios from 'axios';

let url = "https://cidadao-api.voxcity.com.br";

const apiUser = axios.create({
    baseURL: url,
    headers: {
        'Acess-Control-Allow-Origin': '*'
    }
});

axios.defaults.withCredentials = true;

export default apiUser;