import React,{useContext,useEffect,useState} from 'react'
import {UserContext} from '../../contexts/userContext';
import { cpf } from 'cpf-cnpj-validator';
import { RxAvatar} from 'react-icons/rx'
import './style.css'

const Profile = () => {
    const { usuario } = useContext(UserContext);
    const [selectUsuario, setSelectUsuario] = useState();

    useEffect(() => {
        let newUser = usuario ? { ...usuario } : {};
        setSelectUsuario(newUser);
    }, [usuario]);

    function transformarData(data) {
        const dataFormatada = data?.slice(0,10);
        return dataFormatada?.split('-').reverse().join('/');
    }

    return (
        <>

            <div className="profile-header">
                {selectUsuario?.urlImagemPerfil ? (
                    <img className='profile-avatar' src={selectUsuario?.urlImagemPerfil} alt="imagem de perfil" style={{padding:"5px"}} />
                ):(
                    <RxAvatar size={60} color='#142a4c' />
                )}
                <h4>Olá, {selectUsuario?.nome}. Seja Bem vindo a agenda!</h4>
            </div>

                
            <div className="profile-container">
                <div className="profile-info">
                    <h3>Informações Pessoais:</h3>
                    <div className="profile-info-container">
                        <div className="profile-info-item">
                            <h5>Nome:</h5>
                            <p> {selectUsuario?.nome}</p>
                        </div>
                        <div className="profile-info-item">
                            <h5>CPF:</h5>
                            <p> {cpf.format(selectUsuario?.cpf)}</p>
                        </div>
                        <div className="profile-info-item">
                            <h5>Telefone:</h5>
                            <p> {selectUsuario?.telefone}</p>
                        </div>
                        <div className="profile-info-item">
                            <h5>Email:</h5>
                            <p> {selectUsuario?.email}</p>
                        </div>
                        <div className="profile-info-item">
                            <h5>Endereço:</h5>
                            <p> {selectUsuario?.endereco}</p>
                        </div>
                        <div className="profile-info-item">
                            <h5>Data de Nascimento:</h5>
                            <p> {transformarData(selectUsuario?.dataNascimento)}</p>
                        </div>
                    </div>
                </div>
            </div>      
        </>   


    )
}

export default Profile
