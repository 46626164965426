import React,{ createContext, useState, useEffect,useContext,useCallback } from "react";
import apiUser from '../apiUser'
import { UserContext } from "./userContext";

export const UserConfigContext = createContext({});

export const UserConfigProvider = ({ children }) => {
  const context = useContext(UserContext);
  const [usuario, setUsuario] = useState(null);
  const socket = context.socket;

  useEffect(() => {
    let newUser = context.usuario ? {...context.usuario} : null
    setUsuario(newUser)
  }, [context.usuario])

  function initSocket() {
    console.log("init user socket");
    socket.on("update historico", (data) => {
      updateHistorico(data);
    });
  }

  function offSocket() {
    socket.off("update historico");
  }

  useEffect(() => {
    if (!socket) return;
    initSocket();
    return () => {
      offSocket()
    };
  }, [socket,usuario]);

  
  const updateHistorico = useCallback((data) => {
    let newHistorico = { ...usuario };
    newHistorico.historico.push(data);
    setUsuario(newHistorico);
  }, [usuario]);

  const uploadImage = async (image) => {
    await apiUser.post('/upload/image',image, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }).then((response) => {
      let newUsuario = { ...usuario};
      let newMessage = response.data.message
      delete newUsuario.urlImagemPerfil;
      newUsuario = Object.assign(newUsuario, response.data)
      context.setAlert({ visible: true, title: "Sucesso", placeholder: newMessage, type: "success"});
      setUsuario(newUsuario)
    }).catch((error) => {
      console.log(error,error.response)
    })
  }

  function retirarRepeticoesHistorico(historico) {
    let oldHistorico = historico;

    const marcacoes = oldHistorico.filter((obj) => obj?.acao === "marcar"); //Separar as marcacoes

    const desmarcacoes = oldHistorico.filter(
      (obj) => obj?.acao === "desmarcar"
    ); //Separar as desmarcacoes

    const confirmados = oldHistorico.filter((obj) => obj?.acao === "confirmar"); //Separar os confirmados

    const cancelados = oldHistorico.filter((obj) => obj?.acao === "cancelar"); //Separar os cancelados

    const alvosDesmarcacao = desmarcacoes.map((obj) => obj?.alvoAcao); //Separar os alvoAcao das desmarcacoes
    const alvosCancelamento = cancelados.map((obj) => obj?.alvoAcao); //Separar os alvoAcao dos cancelamentos
    const alvosConfirmacao = confirmados.map((obj) => obj?.alvoAcao); //Separar os alvoAcao dos confirmados

    const marcacoesFiltradas = marcacoes.filter((obj) => {
      const alvoMarcacao = obj?.alvoAcao;
      //filtrar todas marcacoes que não tenhão alvoAcao igual a alguma desmarcacao, cancelamento ou confirmacao
      const alvoDesmarcacao = alvosDesmarcacao.find(
        (alvo) => alvo?.dataMarcada === alvoMarcacao?.dataMarcada
      );
      const alvoCancelamento = alvosCancelamento.find(
        (alvo) => alvo?.dataMarcada === alvoMarcacao?.dataMarcada
      );
      const alvoConfirmacao = alvosConfirmacao.find(
        (alvo) => alvo?.dataMarcada === alvoMarcacao?.dataMarcada
      );
      if (alvoDesmarcacao || alvoCancelamento || alvoConfirmacao) {
        return false;
      } else {
        return true;
      }
    }); //Filtrar as marcacoes que nao tem alvoAcao igual a alguma desmarcacao
    return [
      ...marcacoesFiltradas,
      ...desmarcacoes,
      ...confirmados,
      ...cancelados,
    ];
  }
    
  return (
    <UserConfigContext.Provider value={{ 
        usuario,
        setUsuario, 
        updateHistorico,
        retirarRepeticoesHistorico,
        uploadImage,   
    }}>
        {children}
  </UserConfigContext.Provider>
  )
}

