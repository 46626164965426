import React from 'react'

const SelectServicos = ({prefeituraSelecionada,handleServiceChange}) => {
  return (
    <div className='items-select'>
        <label>
        Serviço:
        </label>
        <select className="select-agendamento__select" onChange={handleServiceChange}>
        {prefeituraSelecionada?.services?.agenda?.serviços?.length > 0 ? (
            <option value="0">Selecione um Serviço</option>
        ) : (
            <option value="0">Não há Serviços disponíveis</option>
        )}
        {prefeituraSelecionada?.services?.agenda?.serviços?.map((service, index) => {
            return (
            <option key={index} value={service._id}>
                {service.nome}
            </option>
            );
        })}
        </select>
    </div>
  )
}

export default SelectServicos
