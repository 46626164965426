import React, { useContext, useEffect, useState } from "react";
import { PrefeituraContext } from "../../../../contexts/prefeituraContext";
import { AgendaContext } from "../../../../contexts/agendaContext";
import { UserConfigContext } from "../../../../contexts/userConfigContext";
import "../../style.css";
import { Paginacao } from "./pagination";
import LoadingSpinner from "../../../Loader/load";

const Historico = () => {
  const { desmarcarHorariohistorico , nomesDosMeses,confirmarAgendamento,loadingConfirmar,loadingDesmarcar} = useContext(AgendaContext);
  const { usuario, retirarRepeticoesHistorico } = useContext(UserConfigContext);
  const { acharEspecialidade, acharLocal, acharServico, acharProfissional } = useContext(PrefeituraContext);
  const [historico, setHistorico] = useState([]);
  const diasSemana = ["Domingo","Segunda","Terça","Quarta","Quinta","Sexta","Sábado"];
  const [marcadoCor, setMarcadoCor] = useState("rgba(154,195,28,0.3)");
  const [desmarcadoCor, setDesmarcadoCor] = useState("rgba( 255, 0, 0, 0.4 )");
  const [confirmadoCor, setConfirmadoCor] = useState("rgba( 0, 0, 250, 0.4 )");
  const [itemsPerPage, setItemsPerPage] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [paginatedItems, setPaginatedItems] = useState([]);

  useEffect(() => {
    if (!usuario) return;
    let newHistorico = usuario ? [...usuario?.historico] : [];
    newHistorico = retirarRepeticoesHistorico(newHistorico);
    setHistorico(newHistorico);
  }, [retirarRepeticoesHistorico, usuario, usuario.historico]);


  function translateDate(dataString) {
    dataString = new Date(dataString);
    const diaSemana = diasSemana[dataString?.getDay()];
    const dia = dataString?.getDate();
    const mes = nomesDosMeses[dataString?.getMonth()];
    const ano = dataString?.getFullYear();
    const hora = dataString?.getHours() + 3;
    const minutos = dataString?.getMinutes();
    const novaData = `${diaSemana}, ${dia} de ${mes} de ${ano} às ${hora}:${
      minutos < 10 ? "0" + minutos : minutos
    }`;
    return novaData;
  }

  function renderizarBotao(item) {
    let dataMarcada = new Date(item?.alvoAcao?.dataMarcada);
    let dataAtual = new Date();
    if(item.acao === "marcar" &&  dataMarcada > dataAtual){
      return (
          loadingDesmarcar ? (
            <LoadingSpinner />
          )
          : (        
            <button
            className="desmarcarButton"
            onClick={() => desmarcar(item)}
          >
            Desmarcar
          </button>
          )
      );
    }
  }

  function desmarcar(item) {
    let Horario = {
      servico: item?.alvoAcao.servico, //adicionar servico para funcao desmarcarHorario
      especialidade: item?.alvoAcao.especialidade, //adicionar especialidade para funcao desmarcarHorario
      local: item?.alvoAcao.local, //adicionar local para funcao desmarcarHorario
      data: item?.alvoAcao.dataMarcada, //adicionar data para funcao desmarcarHorario
      profissional: item?.alvoAcao.profissional, //adicionar profissional para funcao desmarcarHorario
      prefeitura: item.alvoAcao.prefeitura, //adicionar prefeitura para funcao desmarcarHorario
      usuario: usuario._id, //adicionar usuario para funcao desmarcarHorario
    };
    //delete agenda.dataMarcada
    desmarcarHorariohistorico(Horario);
  }

  const paginate = (array, page_size, page_number) => {
    array.sort((a, b) => {
      const dateA = new Date(a.alvoAcao.dataMarcada);
      const dateB = new Date(b.alvoAcao.dataMarcada);
      return dateB - dateA});
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };

 

  useEffect(() => {
    const newPaginate = paginate(historico, itemsPerPage, currentPage);
    setPaginatedItems(newPaginate);
  }, [historico, itemsPerPage, currentPage]);
  
  return (
    <>
      <div className="historico-container">
        {historico.length > 0 ? (
          paginatedItems.map((item, index) => {
            const itemCor = item.acao === "marcar" ? marcadoCor : item.acao === "confirmar" ? confirmadoCor : desmarcadoCor;

            return (
              <div className="historico-item-wrapper" key={index}>
                <div className="historico-item" style={{ marginLeft: "20px" }}>
                  <h3
                    style={{
                      marginLeft: "20px",
                      backgroundColor: `${itemCor}`,
                    }}
                  >
                    Agendamento{" "}
                    {
                      item.acao === "marcar" ? "Marcado" : item.acao === "confirmar" ? "Confirmado" : "Desmarcado"
                    }
                  </h3>
                  <div className="item">
                    <h3>Data: </h3>{" "}
                    <p>{translateDate(item.alvoAcao?.dataMarcada)}</p>
                  </div>
                  <div className="item">
                    <h3>Local: </h3> <p>{acharLocal(item.alvoAcao?.local)}</p>
                  </div>
                  <div className="item">
                    <h3>Especialidade:</h3>{" "}
                    <p>{acharEspecialidade(item.alvoAcao?.especialidade)}</p>
                  </div>
                  <div className="item">
                    <h3>Serviço: </h3>{" "}
                    <p>{acharServico(item.alvoAcao?.servico)}</p>
                  </div>
                  <div className="item">
                    <h3>Profissional: </h3>{" "}
                    <p>{acharProfissional(item?.alvoAcao)}</p>
                  </div>
                  <div className="item" style={{ justifyContent: "center" }}>
                    {renderizarBotao(item)}
                  </div>
                  <div className="item" style={{ justifyContent: "center" }}>
                    {
                      item.acao === "marcar" && item.alvoAcao.hashConfirmacao ?(

                        loadingConfirmar ? (
                          <LoadingSpinner />
                        ):(
                          <button
                          className="confirmarButton"
                          onClick={() => confirmarAgendamento(item.alvoAcao.hashConfirmacao,item.alvoAcao.prefeitura)}
                        >
                          Confirmar Agendamento
                        </button>
                        )

                      ):null
                    }
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <h1 style={{color:"#142a4c",width:"100%",textAlign:"center"}}>Não há histórico</h1>
        )}
      </div>
      <div className="pag-div">
        <Paginacao
          items={historico}
          itemsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
          activePage={currentPage}
          setActivePage={setActivePage}
        />
      </div>
    </>
  );
};

export default Historico;
