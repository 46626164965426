import React, { useEffect, useState, useContext } from 'react';
import { AgendaContext } from '../../../../contexts/agendaContext';
import { UserContext } from '../../../../contexts/userContext';
import ConfirmarAgendamento from './Confirmar/confirmarAgendamento';
import DesmarcarAgendamento from './Confirmar/desmarcarAgendamento';
import Header from '../AgendaDia/Header/header.jsx'
import Content from '../AgendaDia/Content/content.jsx'
import "../calendarStyle.css";
import Arrows from './Arrows';

const AgendaDia = (props) => {

  const { dadosAgendamento, markAgenda, agendas, diaSelecionado,unMarkAgenda } = useContext(AgendaContext);
  const { usuario } = useContext(UserContext);
  const [newMarkedDates, setNewMarkedDates] = useState([]);
  const [informartions, setInformations] = useState({});
  const [showConfirmarAgendamento, setShowConfirmarAgendamento] = useState(false);
  const [showDesmarcarAgendamento, setShowDesmarcarAgendamento] = useState(false);
  const [datasDia, setDatasDia] = useState({});
  const [disponiveis, setDisponiveis] = useState([]);
  const [user, setUser] = useState({});

  useEffect(() => {
    let newInformations = agendas ? [...agendas] : [];
    if (newInformations.length > 0) {
      let newInfo = { ...newInformations[0] }
      delete newInfo.datasMarcadas
      newInfo = { ...newInfo, prefeitura: dadosAgendamento.prefeitura }
      setInformations(newInfo)
    }
  }, [agendas, dadosAgendamento]);

  useEffect(() => {
    let newDisponiveis = props.disponiveis ? [...props.disponiveis] : [];
    setDisponiveis(newDisponiveis);
  }, [props.disponiveis]);

  useEffect(() => {
    let newMarkedDates = props.marcadas ? [...props.marcadas] : [];
    setNewMarkedDates(newMarkedDates);
  }, [props.marcadas]);

  useEffect(() => {
    let newUsuario = usuario ? { ...usuario } : {};
    setUser(newUsuario);
  }, [usuario]);

  const closeModalOnOutsideClick = (e) => {
    if (e.target.className === "bg-modal" && !showConfirmarAgendamento) {
      props.setShowModal(false);
      props.mostrarHistorico(true)
    }
    if (
      (e.target.className === "bg-modal-agenda-dia" ||
        e.target.className === "bg-modal") &&
      showConfirmarAgendamento
    ) {
      setShowConfirmarAgendamento(false);
      props.setShowModal(true);
    }
    if (e.target.className === "bg-modal-agenda-dia" && showDesmarcarAgendamento) {
      setShowDesmarcarAgendamento(false);
      props.setShowModal(true);
    }
  };

  // Função para obter a data de um elemento do array
  const getDate = (item) => {
    if (typeof item === "object") {
      return item.data;
    }
    return item;
  };

  // Função de comparação para ordenação das datas
  const compareDates = (a, b) => {
    const dateA = new Date(getDate(a));
    const dateB = new Date(getDate(b));
    return dateA - dateB;
  };

  const prevDate = () => {
    const prevDay = new Date(diaSelecionado);
    const currentDay = prevDay.getDate();
    prevDay.setDate(currentDay - 1);
    const newDayString = prevDay.toString();
    props.handleTileClick(newDayString);
  };

  const nextDate = () => {
    const nextDay = new Date(diaSelecionado);
    const currentDay = nextDay.getDate();
    nextDay.setDate(currentDay + 1);
    const newDayString = nextDay.toString();
    props.handleTileClick(newDayString);
  };

  const addNewMarkedDate = (novaDataMarcada) => {
    const markedDates = [...newMarkedDates, novaDataMarcada];
    markedDates.sort(compareDates);
    props.setMarcadas(markedDates);
    let newMarkedDate = {
      data: novaDataMarcada.data,
      usuario: novaDataMarcada._id,
      profissional: novaDataMarcada.profissional,
      prefeitura: informartions.prefeitura,
      especialidade: informartions.especialidade,
      local: informartions.local,
      serviço: informartions.serviço,
    }
    setShowConfirmarAgendamento(false)
    props.setShowModal(true)
    props.mostrarHistorico(true)
    markAgenda(newMarkedDate);
  };

  const removeMarkedDate = (novaDataMarcada) => {
    const markedDates = [...newMarkedDates];
    const index = markedDates.findIndex((item) => item.data === novaDataMarcada.data);
    markedDates.splice(index, 1);
    markedDates.sort(compareDates);
    props.setMarcadas(markedDates);
    let newMarkedDate = {
      data: novaDataMarcada.data,
      usuario: novaDataMarcada._id,
      profissional: novaDataMarcada.profissional,
      prefeitura: informartions.prefeitura,
      especialidade: informartions.especialidade,
      local: informartions.local,
      serviço: informartions.serviço,
    }
    props.setMarcadas(markedDates);
    setShowDesmarcarAgendamento(false)
    props.mostrarHistorico(true)
    unMarkAgenda(newMarkedDate);
  };

  useEffect(() => {
    let newDisponiveis = disponiveis ? [...disponiveis] : [];
    let newMarkeds = newMarkedDates ? [...newMarkedDates] : [];

    let myMarkedDates = newMarkeds.filter((data) => data.usuario === user._id);
    myMarkedDates = myMarkedDates.map((data) => data.data);

    newDisponiveis = newDisponiveis.filter((data) => {
      if (myMarkedDates.includes(data.data) && data.usuario !== user._id) {
        return false
      }
      return true
    })

    let newDatasDia = {
      funcionamento: [...newMarkeds, ...newDisponiveis],
      marcadas: [...newMarkeds],
      disponiveis: [...newDisponiveis],
    };
    // Ordenar as datas
    newDatasDia.funcionamento.sort(compareDates);
    setDatasDia(newDatasDia);
  }, [newMarkedDates, disponiveis]);


  return (
    <>
      <div className="bg-modal" onClick={closeModalOnOutsideClick}></div>
      <div className="modal-calendario-wrapper">
        <div className="rasgadoDaFolha"></div>
        <div className="furoDaFolha"></div>
        <div className="modal-calendario">
          <div className="modal-calendario-header">
            <Header informartions={informartions} />
          </div>
          <div className="modal-calendario-content">
            <Content datasDia={datasDia} user={user} setShowConfirmarAgendamento={setShowConfirmarAgendamento} setShowDesmarcarAgendamento={setShowDesmarcarAgendamento} />
          </div>
          <Arrows prevDate={prevDate} nextDate={nextDate} showDesmarcarAgendamento={showDesmarcarAgendamento} showConfirmarAgendamento={showConfirmarAgendamento} />
        </div>
      </div>
      {showConfirmarAgendamento && (
        <ConfirmarAgendamento showConfirmarAgendamento={showConfirmarAgendamento} setShowConfirmarAgendamento={setShowConfirmarAgendamento} closeModalOnOutsideClick={closeModalOnOutsideClick} diaSelecionado={diaSelecionado} addNewMarkedDate={addNewMarkedDate} />)
      }

      {showDesmarcarAgendamento && (
        <DesmarcarAgendamento showDesmarcarAgendamento={showDesmarcarAgendamento} setShowDesmarcarAgendamento={setShowDesmarcarAgendamento} closeModalOnOutsideClick={closeModalOnOutsideClick} diaSelecionado={diaSelecionado} removeMarkedDate={removeMarkedDate} />)
      }
    </>
  )
}

export default AgendaDia;