import React, { useContext, useState } from 'react';
import { UserContext } from '../../contexts/userContext';
import { BiUser, BiLock, BiIdCard, BiPhone, BiCalendar, BiHomeAlt, BiMailSend } from 'react-icons/bi';
import { cpf } from 'cpf-cnpj-validator';
import logo from '../../assets/images/cocaldosul_logo.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import env from 'react-dotenv';

const Cadastro = () => {
  const { cadastro } = useContext(UserContext);
  const [erros, setErros] = useState({});
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [novoUsuario, setNovoUsuario] = useState({
    nome: '',
    senha: '',
    confirmarSenha: '',
    email: '',
    endereco: '',
    dataNascimento: '', // Alterada para usar apenas dataNascimento
    cpf: '',
    telefone: '',
    historico: [],
    ativo: true,
    confirmado: false,
    urlImagemPerfil: '',
    prefeitura: env.ID_PREFEITURA,
  });

  function handleChange(campo, valor) {
    setNovoUsuario({ ...novoUsuario, [campo]: valor });
    setErros({ ...erros, [campo]: '' });
  }

  const validateForm = () => {
    let errors = {};

    if (!cpf.isValid(novoUsuario.cpf)) {
      errors.cpf = 'CPF inválido';
    } else if (!novoUsuario.dataNascimento) {
      errors.dataNascimento = 'Data de nascimento inválida, insira no formato dd/mm/aaaa';
    } else if (novoUsuario.endereco.length === 0) {
      errors.endereco = 'Endereço inválido';
    } else if (novoUsuario.nome.length === 0) {
      errors.nome = 'Nome inválido';
    } else if (novoUsuario.telefone.length === 0) {
      errors.telefone = 'Telefone inválido';
    } else if (novoUsuario.senha.length < 4) {
      errors.senha = 'Senha deve ter no mínimo 4 caracteres';
    } else if (novoUsuario.senha !== novoUsuario.confirmarSenha) {
      errors.confirmarSenha = 'Senhas não conferem';
    } else if (!emailRegex.test(novoUsuario.email)) {
      errors.email = 'E-mail inválido';
    }
    setErros(errors);

    return Object.keys(errors).length === 0;
  };

  function enviarCadastro(event) {
    event.preventDefault();
    let formIsValid = validateForm();
    if (formIsValid) {
      delete novoUsuario.confirmarSenha;
      cadastro(novoUsuario);
    }
  }

  function backToLogin() {
    window.location.href = '/login';
  }

  return (
    <div className='cadastro-wrapper'>
      <div className='cadastro-container'>
        <div className='cadastro-header'>
          <img className='logo' src={logo} alt='logo' />
        </div>

        <div className='cadastro-form'>
          <div className='cadastro-input-container'>
            <div className='cadastro-input-content'>
              <input className='cadastro-input' type='text' placeholder='Nome' onChange={(event) => handleChange('nome', event.target.value)} />
              <BiUser className='cadastro-icon' />
            </div>
            <div className='cadastro-error-container'>
              {erros.nome && <p className='cadastro-errors'>{erros.nome}</p>}
            </div>
          </div>

          <div className='cadastro-input-container'>
            <div className='cadastro-input-content'>
              <input className='cadastro-input' type='text' placeholder='CPF' onChange={(event) => handleChange('cpf', event.target.value)} />
              <BiIdCard className='cadastro-icon' />
            </div>
            <div className='cadastro-error-container'>
              {erros.cpf && <p className='cadastro-errors'>{erros.cpf}</p>}
            </div>
          </div>
          <div className='cadastro-input-container'>
            <div className='cadastro-input-content'>
              <input className='cadastro-input' type='text' placeholder='Telefone' onChange={(event) => handleChange('telefone', event.target.value)} />
              <BiPhone className='cadastro-icon' />
            </div>
            <div className='cadastro-error-container'>
              {erros.telefone && <p className='cadastro-errors'>{erros.telefone}</p>}
            </div>
          </div>
          <div className='cadastro-input-container'>
            <div className='cadastro-input-content'>
              <DatePicker
                selected={novoUsuario.dataNascimento ? new Date(novoUsuario.dataNascimento) : null}
                onChange={(date) => handleChange('dataNascimento', date.toISOString())}
                dateFormat='dd/MM/yyyy'
                className='cadastro-input cadastro-datepicker'
                placeholderText='Data Nascimento'
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                popperPlacement='bottom-start'
              />
              <BiCalendar className='cadastro-icon' />
            </div>
            <div className='cadastro-error-container'>
              {erros.dataNascimento && <p className='cadastro-errors'>{erros.dataNascimento}</p>}
            </div>
          </div>

          <div className='cadastro-input-container'>
            <div className='cadastro-input-content'>
              <input className='cadastro-input' type='text' placeholder='Endereço' onChange={(event) => handleChange('endereco', event.target.value)} />
              <BiHomeAlt className='cadastro-icon' />
            </div>
            <div className='cadastro-error-container'>
              {erros.endereco && <p className='cadastro-errors'>{erros.endereco}</p>}
            </div>
          </div>

          <div className='cadastro-input-container'>
            <div className='cadastro-input-content'>
              <input className='cadastro-input' type='text' placeholder='E-mail' onChange={(event) => handleChange('email', event.target.value)} />
              <BiMailSend className='cadastro-icon' />
            </div>
            <div className='cadastro-error-container'>
              {erros.email && <p className='cadastro-errors'>{erros.email}</p>}
            </div>
          </div>

          <div className='cadastro-input-container'>
            <div className='cadastro-input-content'>
              <input className='cadastro-input' type='password' placeholder='Senha' onChange={(event) => handleChange('senha', event.target.value)} />
              <BiLock className='cadastro-icon' />
            </div>
            <div className='cadastro-error-container'>
              {erros.senha && <p className='cadastro-errors'>{erros.senha}</p>}
            </div>
          </div>

          <div className='cadastro-input-container'>
            <div className='cadastro-input-content'>
              <input className='cadastro-input' type='password' placeholder='Confirmar senha' onChange={(event) => handleChange('confirmarSenha', event.target.value)} />
              <BiLock className='cadastro-icon' />
            </div>
            <div className='cadastro-error-container'>
              {erros.confirmarSenha && <p className='cadastro-errors'>{erros.confirmarSenha}</p>}
            </div>
          </div>

          <div className='cadastro-termos-div'>
            <p className="cadastro-termos">Ao realizar o cadastro você estará aceitando nossos</p><a className='cadastro-termos-link' target='_blank' rel='noreferrer' aria-label='Termos de uso e politicas de privacidade' href='/termos'>Termos de uso e políticas de privacidade.</a>            
          </div>

          <div className='cadastro-div-buttons'>
            <button className='cadastro-button' onClick={enviarCadastro}>Cadastrar</button>
            <button className='cadastro-button' onClick={backToLogin}>Voltar</button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Cadastro;