import React,{ useState, useEffect,useContext } from "react";
import { AgendaContext } from "../../../../../contexts/agendaContext"; 
import { UserContext } from "../../../../../contexts/userContext";
import { PrefeituraContext } from "../../../../../contexts/prefeituraContext";
import "../../calendarStyle.css";

const ConfirmarAgendamento = (props) => {
  const { usuario } = useContext(UserContext);
  const { acharEspecialidade,acharLocal } = useContext(PrefeituraContext);
  const { dadosAgendamento, marcarHorario,horarioSelecionado } = useContext(AgendaContext)
  const [agendamento, setAgendamento] = useState({})
  const [data, setData] = useState({})
  const [userID, setUserID] = useState("")
  
  useEffect(() => {
    let newDados = dadosAgendamento ? {...dadosAgendamento} : {}
    setAgendamento(newDados)
  }, [dadosAgendamento])

  useEffect(() => {
    let dia = horarioSelecionado ? {...horarioSelecionado} : {}
    setData(dia)
  }, [horarioSelecionado])

  useEffect(() => {
    let newUser = usuario ? {...usuario} : {}
    setUserID(newUser._id)
  },[usuario])

  const enviarAgendamento = () => {
    let dados = {...agendamento}
    dados = {...dados, ...data}
    marcarHorario(dados)
    let newDate = {
      data: dados.data,
      usuario: userID,
      profissional: dados.profissional,
    }
    props.addNewMarkedDate(newDate)
  }

  return (
    <>
        {props.showConfirmarAgendamento && (
        <div
            className="bg-modal-agenda-dia"
            onClick={(e) => props.closeModalOnOutsideClick(e)}
        ></div>
        )}
        <div className="confirmar-card">
            <div className="confirmar-card-header">
                <h2>Confirmar Agendamento</h2>
                {agendamento && (
                  <div className="confirmar-card-info">
                    <p><b>Local:</b>{acharLocal(agendamento.local)}</p> 
                    <p><b>Especialidade:</b>{acharEspecialidade(agendamento.especialidade)}</p>
                  </div>
                )}
            </div>
            <button className="confirmar-button" onClick={enviarAgendamento}>Confirmar</button>
        </div>
    </>
  )
}

export default ConfirmarAgendamento
