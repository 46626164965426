import React,{ useState, useContext } from 'react'
import { UserContext } from '../../contexts/userContext'
import logo from '../../assets/images/cocaldosul_logo.png'
import { BiUser, BiLock } from 'react-icons/bi'
import './style.css'


const Login = () => {
    const { signIn } = useContext(UserContext);
    const [login, setLogin] = useState({email: '', senha: ''});


    function handleEmailChange(event) {
        setLogin({...login, email: event.target.value})

    }
      
    function handlePasswordChange(event) {
        setLogin({...login, senha: event.target.value})
    }

    function logIn() {
        signIn(login.email, login.senha);
    }

    function goToCadastro() {
        window.location.href = '/cadastro';
    }

  return (
    <div className='login-wrapper'>
        <div className='login-container'>
            <div className='login-header'>
                <img className='logo-login' src={logo} alt='logomarca voxcity tecnologia' />
            </div>
            <div className='login-form'>
                <div className="login-input-content">
                    <input className='login-input' type='text' placeholder='Usuário' onChange={handleEmailChange} />
                    <BiUser className='login-icon' />
                </div>

                <div className="login-input-content">
                    <input className='login-input' type='password' placeholder='Senha' onChange={handlePasswordChange} />
                    <BiLock className='login-icon' />
                </div>
              
                <button className='login-button' onClick={logIn}>Login</button>

                <button className='login-button' onClick={goToCadastro}>Cadastro</button>

                <div className='login-links'>
                    <a href='/esqueciSenha' className='login-esqueci'>Esqueci minha senha</a>
                </div>

            </div>
        </div>
      
    </div>
  )
}

export default Login
