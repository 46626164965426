export const termos ={
    titulo:"Termos de uso e políticas de privacidade",
    texto :`<b>INTRODUÇÃO
    
    A <b>VOXCITY <b>TECNOLIGIA <b>LTDA (VOXCITY), por meio de toda sua equipe está 100% (cem por cento) comprometida em resguardar sua privacidade e proteger seus dados. Por entendermos que a sua privacidade é muito importante, tomamos todas as medidas possíveis para protegê-la e desenvolvemos uma Política de Privacidade que explica, de forma detalhada, como as suas informações e dados pessoais são coletados, usados, compartilhados e armazenados por meio dos nossos sites, softwares e respectivos serviços.
    Esta Política de Privacidade se aplica quando você utiliza os nossos serviços que envolvem coleta de dados online e offline, incluindo os dados pessoais coletados por meio de nossos vários serviços e canais, como websites, aplicativos e redes sociais dentre outros.
    A aceitação da nossa Política será feita quando você se cadastrar em nossa plataforma para usufruir de um dos nossos serviços. Tal aceitação se dará quando você, de forma afirmativa, concordar com o uso dos seus dados para cada uma das finalidades por nós aqui descritas. Isso indicará que você está ciente e em total acordo com a forma como utilizaremos as suas informações e os seus dados. Se você optar por não fornecer os dados pessoais necessários para utilizar os nossos softwares ou para a prestação dos nossos serviços, ou não concordar com essa Política, nós não poderemos fornecer a você nossos produtos e/ou serviços. Caso não concorde com esta Política, por favor, não continue o seu procedimento de registro e não use os nossos serviços.
    Para facilitar a leitura, a nossa Política está formatada a partir de questionário, elaborado de forma simples e acessível para que você possa ler e entender a forma como utilizamos os seus dados para lhe oferecer uma experiência segura e confortável no uso dos softwares, aplicativos e serviços que oferecemos. Compreendemos ser esta a melhor maneira de apresentar a forma como cuidamos dos seus dados pessoais e como cumprimos as obrigações legais preconizadas na Lei Geral de Proteção de Dados (Lei n. 13.709/2018).
    
    <b>1. <b>TIPOS <b>DE <b>DADOS <b>COLETADOS <b>PELA <b>VOXCITY

    Todos os dados que coletamos são utilizados para a prestação de nossos serviços. Por isso, todos os dados e informações são tratados como confidenciais e somente serão usados para os fins aqui descritos.
    A <b>VOXCITY coleta e trata os seus dados com base nos fundamentos previstos na LGPD, de acordo com a natureza dos dados pessoais fornecidos e do tipo de tratamento de dados envolvido. O principal fundamento utilizado é o legítimo interesse dos nossos clientes e, em situações específicas, podemos fundamentar o tratamento de dados no cumprimento de obrigações legais e regulatórias e no exercício regular de direitos. Para qualquer finalidade em que o seu consentimento deva ser coletado nos termos da lei, o tratamento dos dados depende da sua manifestação livre e inequívoca.
    A quantidade e o tipo de informações coletadas pela VOXCITY variam conforme o uso que você faz dos nossos softwares, aplicativos e serviços. Assim, podemos coletar diferentes dados caso você esteja visitando nosso site ou utilizando nossos softwares, aplicativos e respectivos serviços.
    Aqui buscamos demonstrar, de maneira simples e transparente, a forma pela qual cuidamos das suas informações.
    
    <b>1<b>.<b>1<b>. <b>DO <b>ACESSO <b>AO <b>NOSSO <b>SITE

    Caso você apenas acesse os nossos sites, coletamos seus dados de navegação. No caso, quando você visita o nosso site é inserido um “cookie” no seu navegador por meio do software Google Analytics, para identificar quantas vezes você retorna ao nosso endereço. “Cookies” são identificadores que transferimos para o seu navegador ou dispositivo que nos permitem reconhecer seu navegador ou dispositivo e nos informam como e quando as páginas e recursos em nossos serviços são visitados e quantas pessoas as acessam.
    
    Também usamos cookies e dados de navegação, como URLs (Uniform Resource Locators) para coletar informações sobre a data e a hora da sua visita e as soluções e informações para as quais você pesquisou e visualizou. Como a maioria das empresas de tecnologia, coletamos automaticamente esses dados pessoais e os armazenamos em arquivos de registro sempre que você visita nosso site ou acessa sua conta em nossa rede.
    Podemos usar cookies de sessão (que expiram depois de fechar o navegador) e cookies persistentes (que permanecem no seu computador até que você os exclua) para fornecer uma experiência mais pessoal e interativa em nosso site. Os cookies persistentes podem ser removidos seguindo as instruções do arquivo de ajuda do seu navegador da Internet. Você pode optar por limitar, recusar ou desabilitar os cookies através das configurações do seu navegador. Contudo, ao fazer isso, algumas áreas do nosso site podem não funcionar corretamente, o que poderá impedir que você se beneficie de alguns de nossos recursos.
    
    Esta Política de Privacidade não cobre o uso de cookies por terceiros, e não somos responsáveis por suas políticas e práticas de privacidade. Tenha em atenção que os cookies colocados por terceiros podem eventualmente continuar a monitorar as suas atividades online mesmo depois de ter saído dos nossos serviços.
    Se você interagir com os nossos materiais e nossos conteúdos, coletaremos, além de seus dados de navegação, os seus dados de visita às nossas páginas, e outras informações que você optar por compartilhar conosco através das suas interações com os nossos formulários, landing pages e e-mails, por exemplo.
    Para oferecermos conteúdos e materiais do seu interesse, muitas vezes é necessário cruzar dados que coletamos de você, para que as nossas ações de marketing sejam direcionadas àquilo que você espera receber de nós. Garantimos que esse cruzamento não é feito de forma discriminatória, não ferindo quaisquer direitos ou liberdades individuais. 
    Considerando todos esses pontos, algumas possibilidades de uso dos seus dados poderão ocorrer. Nesse sentido, recomendamos que leia os exemplos abaixo:

    a)	Seu e-mail é utilizado para a operação de envio do material ou informação por você requisitada no preenchimento do formulário. Também pode ser usado para envio de newsletters, sempre relacionadas à área de atuação da VOXCITY.
    
    b)	Funcionários da VOXCITY poderão, eventualmente, entrar em contato via e-mail ou telefone para fazer pesquisas ou apresentar produtos e serviços.
    
    c)	Enviar a você mensagens a respeito de suporte ou serviço, como alertas, notificações e atualizações.
    
    d)	Nos comunicar com você sobre produtos, serviços, promoções, notícias, atualizações, eventos e outros assuntos que você possa ter interesse.
    
    e)	Realizar publicidade direcionada conforme seus gostos, interesses e outras informações coletadas
     
    <b>1<b>.<b>2<b>. <b>DA <b>UTILIZAÇÃO <b>DO <b>SITE <b>E <b>DOS <b>SERVIÇOS <b>OFERECIDOS <b>PELA <b>VOXCITY
    Se você utilizar algum de nossos serviços e/ou produtos, iremos coletar as seguintes categorias e tipos de dados pessoais: dados de contato (nome, e-mail e telefone), dados locacionais (país, estado e cidade), dados da empresa (CNPJ, nome fantasia e razão social), dados de faturamento (nome, CPF, endereço, cartão de crédito) e dados para fins de marketing (suas preferências de marketing e informações sobre seu CRM).
    Para o cadastro de acesso aos serviços e/ou produtos na modalidade de teste gratuito (trial), são coletados: dados de contato (nome, e-mail, cpf e telefone) e dados da empresa (CNPJ, nome fantasia e razão social).
    Para a contratação dos nossos serviços e/ou produtos, são coletados além dos dados anteriores: dados de faturamento e cobrança (razão social ou nome, nome fantasia, CNPJ ou CPF, responsável legal e seu CPF, endereço da sede, dados do cartão de crédito – quando feita a opção por este meio de pagamento – opções quanto ao Simples Nacional, dados complementares para emissão de Nota Fiscal e moeda de pagamento para clientes internacionais)
    
    <b>2<b>. <b>COM <b>QUEM <b>A <b>VOXCITY <b>COMPARTILHA <b>OS <b>SEUS <b>DADOS<b>?
    Nós não alugamos ou vendemos seus dados pessoais para ninguém e nos reservamos no direito de compartilhar suas informações, incluindo dados de localização, cadastro e de interesses, apenas para os fins especificados nesta política de privacidade. Assim, poderemos vir a compartilhar seus dados pessoais com terceiros, nas seguintes situações:
    
    a)	Nossos Parceiros: Empregamos outras empresas para realizar trabalhos em nosso nome e precisamos compartilhar dados pessoais com eles para fornecer produtos e serviços para você. Por exemplo, usamos serviços de hospedagem de dados para armazenar a nossa base de dados, usamos também serviços de meios de pagamento para poder processar os dados de faturamento dos nossos produtos adquiridos por você. Nossos Parceiros somente são autorizados a utilizar os dados pessoais para os fins específicos que eles foram contratados. Portanto, eles não irão utilizar os seus dados pessoais para outras finalidades, além da prestação de serviços prevista contratualmente.
    
    b)	Estatísticas: Os dados armazenados pela VOXCITY podem vir a ser utilizados para fins de estatísticas (analytics). Estes dados são agrupados, com o intuito de proporcionar uma análise macro do cenário, e, portanto, não buscam identificar ou tornar identificável os titulares dos dados pessoais, mas tão somente compreender melhor como é o acesso deles nas plataformas da VOXCITY, a fim de melhorar a prestação de serviços e customizar produtos mais direcionados aos interesses dos usuários.
    
    c)	Para resguardar e proteger direitos da VOXCITY: Reservamo-nos o direito de acessar, ler, preservar e divulgar quaisquer dados que acreditamos serem necessários para cumprir uma obrigação legal ou uma ordem judicial, fazer cumprir ou aplicar nossos Termos de Uso do Software e outros acordos ou proteger os direitos, propriedade ou segurança da VOXCITY, nossos funcionários, nossos usuários ou outros.
    
    
    <b>3<b>. <b>DA <b>EVENTUAL <b>TRANSFERÊNCIA <b>INTERNACIONAL <b>DE <b>DADOS
    A VOXCITY poderá transferir os dados pessoais coletados no Brasil para outros países. Se ocorrer essa transferência será somente para parceiros da VOXCITY que atuam no processamento de dados e essas transferências envolvem apenas empresas parceiras que demonstraram estar em conformidade com a GDPR (regulação geral de proteção de dados) e com as leis brasileiras de proteção de dados.
    A VOXCITY possui sua sede no Brasil e os dados que coletamos são regidos pela lei brasileira. Ao acessar ou usar os serviços da VOXCITY ou fornecer dados pessoais para nós, você concorda com o processamento e a transferência de tais dados para o Brasil e para outros países.
    
    <b>4<b>. <b>DIREITOS <b>DOS <b>TITULARES <b>DE <b>DADOS
    Você sempre poderá optar em não divulgar seus dados para nós, mas tenha em mente que alguns desses dados podem ser necessários para se registrar em nossas plataformas ou usar alguns de nossos recursos e independente disso, você sempre possuirá direitos relativos à privacidade e a proteção dos seus dados pessoais, e além de nos preocuparmos com a segurança desses dados, também nos preocupamos que você tenha acesso e conhecimento de todos os seus direitos relativos a dados pessoais.
    Dessa forma, a partir da Lei Geral de Proteção de Dados (LGPD), das demais leis brasileiras relativas à proteção de dados e da General Data Protection Regulation (GDPR) - (regulação geral de proteção de dados), resumimos abaixo alguns dos seus direitos relativos a privacidade e a proteção dos seus dados pessoais:
    
    a)	Confirmação da existência do tratamento: o primeiro direito é a confirmação da existência do tratamento. Como o próprio nome sugere, é o direito garantido ao titular de confirmar se a empresa (controladora ou operadora) realiza o tratamento de seus dados pessoais.
    
    b)	Acesso aos dados: o segundo direito é o acesso aos dados. Aqui, a lei garante aos titulares o direito de obter uma cópia de seus dados pessoais (dentre outras informações relacionadas). Assim como no caso da confirmação do tratamento, o titular pode requisitar o acesso em formato simplificado ou em formato completo (com o prazo de 15 dias para atender à solicitação).
    
    c)	Correção de dados incompletos, inexatos ou desatualizados: também é garantido ao titular o direito à correção de dados incompletos, inexatos ou desatualizados, que consiste no direito de solicitar que os dados tratados sejam corrigidos ou atualizados.
    
    d)	Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade: o titular tem o direito de pedir a anonimização, bloqueio ou eliminação, caso os dados pessoais tratados pela empresa se mostrem a) desnecessários para a finalidade que justifica a realização do tratamento; b) excessivos em relação ao necessário para alcance da finalidade ou; c) em desconformidade, ou seja, caso não estejam sendo tratados para finalidades específicas ou o tratamento não seja justificável por nenhuma base legal.
    
    e)	Portabilidade dos dados a outro fornecedor de serviço ou produto: é garantido ao titular o direito de solicitar o compartilhamento dos dados fornecidos à empresa, ou seja, a portabilidade dos dados a outro fornecedor de serviço ou produto. Esses dados devem ser transferidos em formato estruturado, em linguagem comum e amplamente utilizada, e de forma “legível” para qualquer computador. 
    
    f)	Eliminação dos dados pessoais tratados com o consentimento do titular: caso não deseje mais que seus dados pessoais sejam tratados pela empresa, o titular tem o direito de solicitar e eliminação de seus dados pessoais da base da empresa. É importante ressaltar, todavia, que esse direito não é absoluto: dados necessários para cumprimento de obrigação legal ou regulatória, bem como dados financeiros e outros tratados com finalidade legítima que transcendem a vontade do titular não devem ser excluídos.
    
    g)	Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados: é direito do titular saber com quem os seus dados estão sendo compartilhados. Aqui, temos presente o princípio da transparência.
    
    h)	Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa: para que o consentimento seja considerado realmente livre, é necessário que a empresa dê a informação sobre a possibilidade de não fornecer consentimento. Junto a essa informação, devem ser apresentadas as consequências de não fornecer o consentimento, como possíveis prejuízos na experiência do usuário, menor customização, limitação de acesso a determinadas “áreas logadas” que necessitem desse consentimento, dentre outras.
    
    i)	Revogação do consentimento: o consentimento pode ser revogado a qualquer momento mediante manifestação expressa do titular, por procedimento gratuito e facilitado. É importante ressaltar que os tratamentos realizados anteriormente sob amparo desse consentimento retirado continuam válidos, até que haja expressa manifestação do titular pela eliminação de tais dados.
     
    Caso você tenha alguma dúvida sobre essas questões e sobre como pode exercer esses direitos, fique à vontade para entrar em contato conosco.
    
    <b>5<b>. <b>POR <b>QUANTO <b>TEMPO <b>OS <b>DADOS <b>SERÃO <b>ARMAZENADOS<b>?
    Nós manteremos seus dados pessoais somente pelo tempo que for necessário para cumprir com as finalidades para as quais os coletamos, inclusive para fins de cumprimento de quaisquer obrigações legais ou contratuais.
    Para determinar o período de retenção adequado para os dados pessoais consideramos a quantidade, a natureza e a sensibilidade dos dados pessoais, o risco potencial de danos decorrentes do uso não autorizado ou da divulgação de seus dados pessoais, a finalidade de processamento dos seus dados pessoais e se podemos alcançar tais propósitos através de outros meios e os requisitos legais aplicáveis.
    Vale destacar, nesse ponto, o direito do titular de dados de demandar a “Eliminação dos dados pessoais tratados com o consentimento do titular”, referido no tópico anterior.
    
    <b>6<b>. <b>COMO<b> <b>FUNCIONA<b> <b>A <b>SEGURANÇA <b>DA <b>INFORMAÇÃO
    Sua conta é protegida com uma senha para sua privacidade e segurança. Se você acessar sua conta por meio de um site ou serviço de terceiros, poderá ter proteções de login adicionais ou diferentes por meio desse site ou serviço. Você deve impedir e evitar o acesso não autorizado de terceiros à sua conta e aos dados pessoais selecionando e protegendo sua senha e/ou outro mecanismo de conexão de forma adequada e limitando o acesso ao seu computador ou dispositivo e navegador, fazendo logout após ter concluído o acesso à sua conta.
    Com a finalidade de garantir a sua privacidade e a proteção dos seus dados pessoais, a VOXCITY adota as melhores práticas de segurança da informação e desenvolvimento seguro existentes no mercado, ou seja, desenhamos produtos e serviços que promovem a proteção dos seus dados e nos quais você pode gerenciar as suas informações diretamente. Aliado a esse conceito, adotamos práticas de criptografia em senhas e demais informações críticas, TLS para transferência de dados entre as aplicações, contratação de serviços cloud com certificações e selos de segurança, como ISO 27001, SOC 1 and SOC 2/SSAE 16/ISAE 3402 e PCI DSS, monitoramento de sistemas 24/7, Firewall de borda com funcionalidades de IDS (Intrusion Detection System), Anti-malware, Anti-phishing, controle de conteúdo e VPN.
    Nós nos esforçamos para proteger a privacidade de sua conta e de outros dados pessoais que mantemos em nossos registros, mas infelizmente não podemos garantir total segurança. Entrada ou uso não autorizado de conta, falha de hardware ou software e outros fatores podem comprometer a segurança dos seus dados pessoais a qualquer momento. Por isso, nos ajude a manter um ambiente seguro para todos.
    Além de adotar boas práticas de segurança em relação a sua conta e aos seus dados, caso você identifique ou tome conhecimento de algo que comprometa a segurança dos seus dados, você pode entrar em contato conosco pelos canais de atendimento disponíveis.
    
    <b>7. <b>DPO<b> <b>(<b>Data<b> <b>Protection <b>Officer<b>) 
    A VOXCITY, enquanto processadora dos dados pessoais coletados nos termos desta Política de Privacidade, busca estar em conformidade com as leis e com os mais elevados padrões de proteção de dados no Brasil e no mundo.
    Por isso, decidimos nomear um Data Protection Officer (DPO), que será o representante da VOXCITY responsável por supervisionar todas as questões relacionadas a esta Política de Privacidade e ao tema da proteção de dados.
    Se você acredita que suas informações pessoais foram usadas de maneira incompatível com a Lei, com a Política de Privacidade ou suas escolhas ou se você tiver outras dúvidas, comentários ou sugestões, você pode entrar em contato com nosso DPO nos seguintes endereços de contato:
    E-mail: dpo@voxcity.com.br.
     
    <b>8<b>. <b>MUDANÇAS <b>NA <b>POLÍTICA
    Como estamos sempre buscando melhorar nossos serviços, essa Política de Privacidade pode passar por atualizações. Desta forma, recomendamos visitar periodicamente esta página para que você tenha conhecimento sobre as modificações.
    Caso sejam feitas alterações relevantes que ensejem em um novo consentimento seu, iremos publicar essa atualização e solicitar um novo consentimento.
    
    `
}