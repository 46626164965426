import React, { useContext,useState,useEffect } from 'react'
import { BiUser,BiLock } from 'react-icons/bi'
import { UserContext } from '../../../contexts/userContext'
import logo from '../../../assets/images/VOX LOGO Horizintal.png'

const InserirSenhaNova = () => {
    const { inserirSenhaEsquecida } = useContext(UserContext);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [error, setError] = useState({});
    const [senhas, setSenhas] = useState({
        hashConfirmacao: '',
        email: '',
        senha: '', 
        confirmarSenha: '' 
    });

    useEffect(() => {
        let hash = window.location.pathname.split('/inserirSenhaNova')[1];
        hash = hash.slice(1);
        setSenhas({
            ...senhas, hashConfirmacao: hash
        });
    }, []);

    function backToLogin() {
        window.location.href = '/login';
    }

    function handleSenhaChange(campo, valor) {
          setSenhas({ ...senhas, [campo]: valor });
          setError({ ...error, [campo]: '' })
    }

    const validateForm = () => {
        console.log(senhas,error)
        if (!emailRegex.test(senhas.email)) {
            setError({ ...error, email: 'E-mail inválido' });
          } else if (senhas.senha.length < 4 && senhas.confirmarSenha.length < 4) {
            setError({ ...error, senha: 'Senha deve ter no mínimo 4 caracteres' });
          } else if (senhas.senha !== senhas.confirmarSenha) {
            setError({ ...error, confirmarSenha: 'Senhas não conferem' });
          } else {
            setError({});
            atualizarSenha();
          }
    };

    function atualizarSenha() {
        let newpass = {...senhas} 
        console.log(newpass)
        inserirSenhaEsquecida(newpass);
    }

  return (
    <div className='login-wrapper'>
        <div className='login-container'>
            <div className='login-header'>
                <img className='logo' src={logo} alt='logo' />
            </div>
            <div className='login-form'>
                <p className='login-text'>Renovar sua senha!</p>

                <div className="div-esqueci">
                    <div className="login-input-content">
                        <input className='login-input' type='text' placeholder='Escreva seu e-email' onChange={(event) => handleSenhaChange('email', event.target.value)}/>
                        <BiUser className='login-icon' />
                    </div>
                    <div className='cadastro-error-container'>
                        {error.email && <p className='cadastro-errors'>{error.email}</p>}
                    </div>
                </div>


                <div className="div-esqueci">
                    <div className="login-input-content">
                        <input className='login-input' type='password' placeholder='Escreva sua nova senha' onChange={(event) => handleSenhaChange('senha', event.target.value)}/>
                        <BiLock className='login-icon' />
                    </div>
                    <div className='cadastro-error-container'>
                        {error.senha && <p className='cadastro-errors'>{error.senha}</p>}
                    </div>
                </div>

                <div className="div-esqueci">
                    <div className="login-input-content">
                        <input className='login-input' type='password' placeholder='Confirme sua nova senha' onChange={(event) => handleSenhaChange('confirmarSenha', event.target.value)}/>
                        <BiLock className='login-icon' />
                    </div>
                    <div className='cadastro-error-container'>
                        {error.confirmarSenha && <p className='cadastro-errors'>{error.confirmarSenha}</p>}
                    </div>
                </div>


                <button className='login-button' onClick={validateForm} >Enviar</button>
                <button className='login-button' onClick={backToLogin} style={{marginBottom:"20px"}}>Voltar</button>
            </div>
        </div>
    </div>
  )
}

export default InserirSenhaNova
