import React, { useContext } from 'react';

import { BiQuestionMark } from 'react-icons/bi'
import '../style.css';
export default function Confirm(props) {
    const { alert } = props;

    return (
        <>
            <div className='background-modal'>
                <div className='alert-container'>
                    <div className="alert-content">
                        <BiQuestionMark className="alert-icon" />
                        <span className='alert-span'> {alert.placeholder} </span>
                    </div>
                    <div className="alert-container-button" >
                        <button className="alert-warning-ok-button" onClick={props.quitAlertConfirm}>Confirmar</button>
                        <button className="alert-success-ok-button" onClick={props.quitAlert}>Voltar</button>
                    </div>
                </div>
            </div>
        </>
    );
}