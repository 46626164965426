import React,{ useState, useContext, useEffect} from 'react'
import {UserContext} from '../../../contexts/userContext';

const AlterarSenha = (props) => {
  const { alterarSenha, usuario } = useContext(UserContext);
  const [novaSenha, setNovaSenha] = useState({});

  useEffect(() => {
    let idUser = {...usuario};
    idUser = idUser._id;
    setNovaSenha({...novaSenha, _id:idUser});
  }, [usuario]);

  function handleChange(campo, valor) {
    setNovaSenha({ ...novaSenha, [campo]: valor });
  }

  function validateSenha(){
    if(novaSenha.novaSenha === novaSenha.confirmarNovaSenha){
      delete novaSenha.confirmarNovaSenha;
      handleSubmit();
    }else{
      alert('As Senhas não Conferem!');
    }
  }

  function handleSubmit() {
    alterarSenha(novaSenha);
    props.setSelectedTab('perfil')
  }


  return (
    <>

      <div className="config-item">
        <h5>Senha atual:</h5>
        <input type="password" placeholder='Digite sua senha atual' className='config-input' onChange={(event) => handleChange('senha', event.target.value)} />
      </div>

      <div className="config-item">
        <h5>Senha nova:</h5>
        <input type="password" placeholder='Digite sua nova senha' className='config-input' onChange={(event) => handleChange('novaSenha', event.target.value)} />
      </div>

      <div className="config-item">
        <h5>Confirmar nova senha:</h5>
        <input type="password" placeholder='Confirme sua nova senha' className='config-input' onChange={(event) => handleChange('confirmarNovaSenha', event.target.value)} />
      </div>

      <div className="config-div-buttons">
        <button className='config-button' onClick={validateSenha}>Enviar</button>
      </div>

    </>
  )
}

export default AlterarSenha
