import React from 'react';
import { termos } from './termos_Condiçoes_v1';




export default function Termos() {

    function breakLineToBr(str) {
        return str.split('\n').map((item, index) => {
            return (<React.Fragment key={index}>
                {transformLinksTags(item)}<br />
            </React.Fragment>)
        })
    }
    function transformLinksTags(str) {
        const linkRegex = /(\b(?:https?|ftp|file):\/\/|www\.)\S+/gi;
        const btag = /<b>\w+/g;
        const transformedArray = str.split(' ').map((word, index) => {
            if (word.match(linkRegex)) {
                const url = word.startsWith('www.') ? `http://${word}` : word;
                return <a href={url} key={index} target="_blank" rel="noopener noreferrer"> &nbsp;{word}</a>;
            }else if(word.match(btag)){
                return <b key={index}>&nbsp;{word.replace(/<\/?b>/g,'')}</b>;
            } else {
                return ` ${word}`
            }
        });
        return transformedArray;
    }
 
    return (
      <div style={{width:"80%"}}>
        <h1>{termos.titulo}</h1>
        <p style={{textAlign:"justify",wordBreak:"break-word"}}>{breakLineToBr(termos.texto)}</p>
      </div>
    );
}