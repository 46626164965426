import { createContext , useState, useEffect,useContext } from "react";
import apiUser from "../apiUser";
import { UserContext } from "./userContext";
import env from "react-dotenv";
export const PrefeituraContext = createContext({});

export const PrefeituraProvider = ({ children }) => {
    const [prefeitura, setPrefeitura] = useState(null);
    const { alert,setAlert} = useContext(UserContext);
   
    const receberPrefeitura = async (prefeitura) => {
      let _id = env.ID_PREFEITURA ? env.ID_PREFEITURA : '64cbddde966805ed9d1c1eec';
      await apiUser.post('/agendamento/prefeitura',{prefeitura : {_id}}).then((response) => {
          let newPrefeitura = response.data
          setPrefeitura(newPrefeitura)
      }).catch((error) => {
          console.log(error)
      })
    }

    useEffect(() => {
      receberPrefeitura();
    }, []);

    function acharEspecialidade(idEspecialidade) {
      let nomeEspecialidade;
      // Para cada local da prefeitura, verificar se ele possui a especialidade selecionada
      prefeitura?.services?.agenda?.locais.forEach(local => {
        let especialidadeEncontrada = local?.especialidades.find((especialidade) => especialidade._id === idEspecialidade);
        if(especialidadeEncontrada){
          nomeEspecialidade = especialidadeEncontrada?.nome;
        }else{
          return '';
        }
      });

      return nomeEspecialidade;
    }
    
    function acharLocal(idLocal) {
      let nomeLocal;
      // Para cada local da prefeitura, verificar se ele possui a especialidade selecionada
      prefeitura?.services?.agenda?.locais.forEach(local => {
        if(local._id === idLocal){
          nomeLocal = local?.nome;
        }else{
          return '';
        }
      });
      return nomeLocal;
    }
    
    function acharHorarioFuncionamento(idLocal, idEspecialidade){
      let horarioFuncionamento;
      // Para cada local da prefeitura, verificar se ele possui a especialidade selecionada
      prefeitura?.services?.agenda?.locais.forEach(local => {
        if(local._id === idLocal){
          local.especialidades.forEach(especialidade => {
            if(especialidade?._id === idEspecialidade){
              horarioFuncionamento = especialidade?.horarioFuncionamento;
            }else{
              return '';
            }
          })
        }else{
          return '';
        }
      });
      return horarioFuncionamento;
    }

    function acharServico(idServico) {
      let servicoEncontrado;
      if(prefeitura?.services?.agenda?.serviços?.length > 0){
        servicoEncontrado = prefeitura?.services.agenda.serviços.find((servico) => servico._id === idServico);
      }
      return servicoEncontrado?.nome;
    }

    function acharProfissional({especialidade,profissional,local}) {
      let profissionalEncontrado;
      // Para cada local da prefeitura, verificar se ele possui a especialidade selecionada
      prefeitura?.services?.agenda?.locais.forEach(localPrefeitura => {
        if(localPrefeitura._id === local){
          localPrefeitura.especialidades.forEach(especialidadePrefeitura => {
            if(especialidadePrefeitura?._id === especialidade){
              especialidadePrefeitura?.profissionais.forEach(profissionalPrefeitura => {
                if(profissionalPrefeitura._id === profissional){
                  profissionalEncontrado = profissionalPrefeitura?.nome;
                }else{
                  return '';
                }
              })
            }else{
              return '';
            }
          })
        }else{
          return '';
        }
      });
      return profissionalEncontrado;
    }


    
    return (
        <PrefeituraContext.Provider value={{prefeitura,acharEspecialidade,acharHorarioFuncionamento,acharLocal,acharServico,acharProfissional,receberPrefeitura}}>
        {children}
        </PrefeituraContext.Provider>
    );
    }