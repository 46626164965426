import React,{ createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import apiUser from '../apiUser'
import jwt_decode from "jwt-decode";
import io from 'socket.io-client';
import env from "react-dotenv";

const UserContext = createContext({});

const UserProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [usuario, setUsuario] = useState(null);
    const [refreshInterval, setRefreshInterval] = useState(null);
    const [signed, setSigned] = useState(false);
    const [alert, setAlert] = useState({ visible: false, title: "", placeholder: ""});
    const [socket, setSocket] = useState(null);
    const navigate = useNavigate();
    

    const signIn = async (email,senha) => {
        await apiUser.post('/login',{usuario:{email,senha}}).then((response) => {
            let newMessage = response.data.error
            if(newMessage){
                setAlert({ visible: true, title: "Negative", placeholder: newMessage, type: "negative"});
                return
            }else{
                setApp(response)
            }
        }).catch((error) => {
            console.log(error)
        })   
    };

    function setApp(response){
        let token = response.data.token  
        let selectUsuario = response.data.usuario
        let refreshToken = response.data.refreshToken
        //apiUser.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        apiUser.defaults.headers.common['authorization'] = token;
        saveTokens(token,refreshToken)
        setUsuario({...selectUsuario})
        setTokenTimeInterval()
        setSigned(true)
        navigate('/home')
    }
        
    const esqueciSenha = async (email) => {
        await apiUser.post('/esqueciSenha',{usuario:{email}}).then((response) => {
            let newMessage = response.data.message
            setAlert({ visible: true, title: "Sucesso", placeholder: newMessage, type: "success"});
        }).catch((error) => {
            console.log(error)
        })
    };

    const inserirSenhaEsquecida = async ({hashConfirmacao,email,senha}) => {
        await apiUser.post('/resetarSenha',{usuario: {hashConfirmacao,email,senha}}).then((response) => {
            let newMessage = response.data.message
            setAlert({ visible: true, title: "Sucesso", placeholder: newMessage, type: "success"});
        }).catch((error) => {
            console.log(error)
        })
    };

    const cadastro = async (usuario) => { 
    await apiUser.post('/',{usuario}).then((response) => {
            let newMessage = response.data.msg
            setAlert({ visible: true, title: "Sucesso", placeholder: newMessage, type: "success"});
            navigate('/login')
        }).catch((error) => {
            console.log(error)
        })
    };
    
    const updateUsuario = async (usuario) => {
        await apiUser.post('/usuarios/update',{usuario}).then((response) => {
            setUsuario({...usuario})
        }).catch((error) => {
            console.log(error)
        })
    }

    const alterarEmail = async (u) => {
        await apiUser.post('/usuarios/alterarEmail',{usuario: u}).then((response) => {
            let newMessage = response.data.message
            delete u.senha;
            let newUser = Object.assign({...usuario} , u )
            setUsuario(newUser)
            setAlert({ visible: true, title: "Sucesso", placeholder: newMessage, type: "success"});
        }).catch((error) => {
            console.log(error)
        })
    }

    const alterarSenha = async (u) => {
        await apiUser.post('/usuarios/alterarSenha',{usuario: u}).then((response) => {
            let newMessage = response.data.message
            setAlert({ visible: true, title: "Sucesso", placeholder: newMessage, type: "success"});
            let newUser = Object.assign({...usuario}, u)
            setUsuario(newUser)   
        }).catch((error) => {
            console.log(error)
        })     
    };

    function saveTokens(token,refreshToken){
        apiUser.defaults.headers.common['authorization'] = token;
        setToken(token)
        localStorage.removeItem('RT')
        if(refreshToken){
            localStorage.setItem('RT',refreshToken)
        }
        
    }

    async function loadStorageData(storageToken) {
        if (storageToken) {
          const decodedToken = jwt_decode(storageToken);
          const currentTime = Date.now() / 1000; // Obter o tempo atual em segundos
          if (decodedToken.exp < currentTime) {
            // Token expirado
            localStorage.removeItem("RT");
            navigate("/login");
            return;
          }
      
          // O token ainda é válido, continue com o login automático
          await apiUser
            .post("/refreshToken", { refreshToken: storageToken })
            .then((response) => {
              setApp(response);
            })
            .catch((error) => {
              console.log(error);
            });
        }
    }

    function setTokenTimeInterval(){
        const interval = setInterval(async () => {
            const storageToken = localStorage.getItem('RT');
            if (storageToken) {
            //req para pegar o token na rota de refreshToken
            await apiUser.post('/refreshToken',{refreshToken:storageToken}).then((response) => {
                setApp(response)
            }).catch((error) => {
                console.log(error)
            })
            }
        }, 1000 * 60 * 14.5);
        setRefreshInterval(interval)
    }


    useEffect(() => {
        function loadApp() {
            const storageToken = localStorage.getItem('RT');
            loadStorageData(storageToken).catch((error) => {
            console.log(error)
            })
        }
        loadApp();
    }, []);
    
    const signOut = (e) => {
        setSigned(false);
        setToken(null);
        setUsuario(null);
        clearInterval(refreshInterval)
        localStorage.clear();
        navigate('/login')
    };

    useEffect(() => {
        if(token && socket === null){
          console.log('conectando socket')
            const newSocket = io.connect(env.REACT_APP_SOCKET_URL,{
                query:{
                        token:token
                    }
                })
            setSocket(newSocket);                    
        }  
        return () => {}
      }, [token,setSocket])
    
  return (
    <UserContext.Provider value={{ 
    signed,
    setUsuario, 
    signIn,
    cadastro,
    esqueciSenha,
    inserirSenhaEsquecida,
    usuario,
    signOut,
    updateUsuario,
    alterarEmail,
    alterarSenha,
    alert,
    setAlert,
    socket,   
    }}>
        {children}
  </UserContext.Provider>
  )
}

export { UserProvider, UserContext}
