import React, { useContext,useEffect,useState } from 'react'
import {UserContext} from '../../../contexts/userContext';
import { UserConfigContext } from '../../../contexts/userConfigContext';
import 'react-datepicker/dist/react-datepicker.css';
import '../style.css';

const AlterarBasico = (props) => {
    const { updateUsuario, usuario } = useContext(UserContext);
    const { uploadImage } = useContext(UserConfigContext);
    const [novoUsuario, setNovoUsuario] = useState({});

    useEffect(() => {
      let usuarioAlterado = usuario ? { ...usuario } : {};
      setNovoUsuario(usuarioAlterado);
    }, [usuario]);
  
    function handleChange(campo, valor) {
      setNovoUsuario({ ...novoUsuario, [campo]: valor });
    };
  
    function handleSubmit(event) {
      event.preventDefault();
      let novoUser = { ...novoUsuario };
      delete novoUser.historico;
      updateUsuario(novoUser);
      props.setSelectedTab('perfil');
    };

    function handleEmail(){
      props.setMostrarEmail(true);
      props.setMostrarBasico(false);
      props.setMostrarSenha(false);
    };
    
    function handleSenha(){
      props.setMostrarEmail(false);
      props.setMostrarBasico(false);
      props.setMostrarSenha(true);
    };

    function handleImageChange(e) {
      const file = e.target.files[0];
      let formData = new FormData()
      formData.append('file', file)
      uploadImage(formData);
    };
  
    return (
      <>
        <div className="config-item">
          <h5>Foto de perfil:</h5>
          <input
            type="file"
            onChange={(e)=>handleImageChange(e)}
            className='config-input'
          />
        </div>

        <div className="config-item">
          <h5>Nome:</h5>
          <input type="text" placeholder='Digite seu novo nome' value={novoUsuario.nome ? novoUsuario.nome : ''}
           className='config-input' onChange={(event) => handleChange('nome', event.target.value)} />
        </div>

        <div className="config-item">
          <h5>Telefone:</h5>
          <input type="text" placeholder='Digite seu novo telefone' value={novoUsuario.telefone ? novoUsuario.telefone : ''} className='config-input' onChange={(event) => handleChange('telefone', event.target.value)} />
        </div>

        <div className="config-item">
          <h5>Endereço:</h5>
          <input type="text" placeholder='Digite seu novo endereço' value={novoUsuario.endereco ? novoUsuario.endereco : ''} className='config-input' onChange={(event) => handleChange('endereco', event.target.value)} />
        </div>

        <div className="config-item">
          <h5>Data de nascimento:</h5>
          <input type="text" placeholder='Digite sua nova data de nascimento' value={novoUsuario.dataNascimento ? novoUsuario.dataNascimento : ''} className='config-input' onChange={(event) => handleChange('dataNascimento', event.target.value)} />
        </div>

        <div className="config-div-buttons">
          <button className='config-button' onClick={handleSubmit}>Salvar</button>
          <button className='config-button' onClick={handleEmail}>Alterar e-mail</button>
          <button className='config-button' onClick={handleSenha}>Alterar senha</button>
        </div>
      </>
    )
}

export default AlterarBasico;
