import React, { useState, useContext } from 'react'
import {UserContext} from '../../../contexts/userContext';

const AlterarEmail = (props) => {
  const { alterarEmail } = useContext(UserContext);
  const [novoEmail, setNovoEmail] = useState({});

  function handleChange(campo, valor) {
    setNovoEmail({ ...novoEmail, [campo]: valor });
  }

  function validateEmail(){
    if(novoEmail.email === novoEmail.confirmarEmail){
      delete novoEmail.confirmarEmail;
      handleSubmit();
    }else{
      alert('Os e-mails não são iguais!');
    }
  }

  function handleSubmit() {
    alterarEmail(novoEmail);
    props.setSelectedTab('perfil')
  }


  return (
    <>

      <div className="config-item">
        <h5>E-mail:</h5>
        <input type="text" placeholder='Digite seu novo e-mail' className='config-input' onChange={(event) => handleChange('email', event.target.value)} />
      </div>
      <div className="config-item">
        <h5>Confirmar e-mail:</h5>
        <input type="text" placeholder='Confirme seu novo e-mail' className='config-input' onChange={(event) => handleChange('confirmarEmail', event.target.value)} />
      </div>

      <div className="config-item">
        <h5>Senha:</h5>
        <input type="password" placeholder='Digite sua senha' className='config-input' onChange={(event) => handleChange('senha', event.target.value)} />
      </div>

      <div className="config-div-buttons">
        <button className='config-button' onClick={validateEmail}>Enviar</button>
      </div>

    </>
  )
}

export default AlterarEmail
