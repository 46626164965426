import React, { useContext, useEffect,useState } from "react";
import { AgendaContext } from "../../../../../contexts/agendaContext";
import { FcCancel } from "react-icons/fc";

const Content = (props) => {
  const { setHorarioSelecionado } = useContext(AgendaContext);
  const [datasDia, setDatasDia] = useState({});

  const translateDate = (dataString) => {
    dataString = new Date(dataString);
    const hora = dataString?.getHours() + 3;
    const minutos = dataString?.getMinutes();
    const novaData = `${hora}:${minutos < 10 ? "0" + minutos : minutos}`;
    return novaData;
  };

  function submitAgendamento(data) {
    setHorarioSelecionado(data);
    props.setShowConfirmarAgendamento(true);
  }

  function submitDesmarcarAgendamento(data) {
    setHorarioSelecionado(data);
    props.setShowDesmarcarAgendamento(true);
  }
  
  useEffect(() => {
    let newDatasDia = props.datasDia ? { ...props.datasDia } : {};
    setDatasDia(newDatasDia);
  }, [props.datasDia]);
  
  return (
    <>
      {datasDia.funcionamento?.length === 0 &&
        datasDia.marcadas?.length === 0 && (
          <div className="modal-calendario-content-empty">
            <h2>FORA DO HORÁRIO DE FUNCIONAMENTO</h2>
          </div>
        )}
      {datasDia.funcionamento?.map((data, index) => {
        if (
          datasDia.marcadas?.map((data2) => data2.data).includes(data.data) &&
          data.usuario === props.user._id
        )
          return (
            <>
              {data.usuario === props.user._id ? (
                <p className="marcado" key={index+ Math.random()}>
                  {translateDate(data.data)} - Agendado por {props.user.nome}
                  <FcCancel
                    className="cancelar-icon"
                    onClick={() => submitDesmarcarAgendamento(data)}
                  />
                </p>
              ) : (
                <p className="marcado" key={index + 1}>
                  {translateDate(data.data)} - Indisponivel
                </p>
              )}
            </>
          );
        else if (!data.usuario) {
          return (
            <p
              className="disponivel"
              key={index + 2}
              onClick={() => submitAgendamento(data)}
            >
              {translateDate(data.data)}
              <span className="marcar-agendamento">Marcar Agendamento</span>
            </p>
          );
        } else {
          return "";
        }
        })}
    </>
  );
};

export default Content;
