import React, { useContext, useEffect, useState } from 'react';
import { PrefeituraContext } from '../../../../contexts/prefeituraContext';
import { AgendaContext } from '../../../../contexts/agendaContext';
import SelectLocal from './selects/selecLocal';
import SelectEspecialidade from './selects/selectEspecialidade';
import SelectServicos from './selects/selectServicos';
import '../../style.css'
import env from 'react-dotenv'


const SelectAgendamento = (props) => {
  const { prefeitura } = useContext(PrefeituraContext);
  const { receberDatasDisponives, receberAgendas, setDadosAgendamento } = useContext(AgendaContext);
  const [servicos, setServicos] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [locais, setLocais] = useState([]);
  const [agendamentos, setAgendamentos] = useState({});
  const [prefeituraSelecionada, setPrefeituraSelecionada] = useState({});
  const [isEnviarButtonVisible, setIsEnviarButtonVisible] = useState(false); // Estado para controlar a visibilidade do botão Enviar
  let idPrefeitura = env.ID_PREFEITURA ? env.ID_PREFEITURA : "64cbddde966805ed9d1c1eec"; 

  useEffect(() => {
    let newPrefeituras = prefeitura ? {...prefeitura} : {};
    setAgendamentos({ ...agendamentos, prefeitura: idPrefeitura})
    setPrefeituraSelecionada(newPrefeituras); 
  }, [prefeitura]);

  useEffect(() => {
    // Verifica se todos os campos foram selecionados para exibir o botão Enviar
    if (
      agendamentos.prefeitura &&
      agendamentos.servico &&
      agendamentos.especialidade &&
      agendamentos.local
    ) {
      setIsEnviarButtonVisible(true);
    } else {
      setIsEnviarButtonVisible(false);
    }
  }, [agendamentos]);


  function handleServiceChange(event) {
    let idService = event.target.value;
    setAgendamentos({ ...agendamentos, servico: idService });
    let servicos = prefeituraSelecionada.services?.agenda?.serviços.find((item) => item._id === idService) //seleciona o serviço selecionado na prefeituraContext
    let locais = servicos.locais.map((item) => {
      return prefeituraSelecionada.services.agenda.locais.find((l) => l._id === item) //seleciona os locais do serviço selecionado na prefeituraContext
    })
    let especialidades = []
    locais.forEach((item) => {
        item.especialidades.forEach((item) => {
          if(especialidades.find((e) => e._id === item._id)) return //verifica se a especialidade já foi adicionada ao array (para não repetir
          especialidades.push(item) //seleciona as especialidades dos locais do serviço selecionado na prefeituraContext
        })
    })
    setEspecialidades(especialidades)
  }

  function handleEspecialidadeChange(event) {
    let idEspecialidade = event.target.value;
    setAgendamentos({ ...agendamentos, especialidade: idEspecialidade });
    let locaisComEspecialidade = [];
    // Para cada local da prefeitura, verificar se ele possui a especialidade selecionada
    prefeituraSelecionada.services.agenda.locais.forEach(local => {
    let especialidadeEncontrada = local.especialidades.find(especialidade => especialidade._id === idEspecialidade);
    if (especialidadeEncontrada) {
      locaisComEspecialidade.push(local);
    }
    });
    setLocais(locaisComEspecialidade) 
  }

  function handleLocalChange(event) {
    let idLocal = event.target.value;
    setAgendamentos({ ...agendamentos, local: idLocal});
  }

  function handleSubmit(event) {
    event.preventDefault();
    receberDatasDisponives(agendamentos);
    receberAgendas(agendamentos);
    setDadosAgendamento(agendamentos);
    props.setMostrarSelect(false);
    props.setMostrarAgendamento(true);
  }

  return (
    <div className="select-agendamento">
 
      {prefeituraSelecionada?.services?.agenda?.serviços?.length > 0 && (
        <SelectServicos prefeituraSelecionada={prefeituraSelecionada} handleServiceChange={handleServiceChange}/>
      )}

      

      {especialidades?.length > 0 && (
        <SelectEspecialidade especialidades={especialidades} handleEspecialidadeChange={handleEspecialidadeChange}/>
      )}

      {locais?.length > 0 && (
        <SelectLocal locais={locais} handleLocalChange={handleLocalChange} />
      )}

      <div className="agenda-div-buttons">
        {isEnviarButtonVisible && ( // Renderiza o botão Enviar apenas quando todos os campos estiverem selecionados
          <button className="agenda-button" onClick={handleSubmit}>
            Enviar
          </button>
        )}
      </div>
    </div>
  );
};

export default SelectAgendamento;
