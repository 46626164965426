import React from 'react'

const SelectEspecialidade = ({ especialidades, handleEspecialidadeChange }) => {
    
  return (
    <div className="items-select">
        <label>Especialidade:</label>
        <select className="select-agendamento__select" onChange={handleEspecialidadeChange}>
            {especialidades.length > 0 ? (
            <option value="0">Selecione uma Especialidade</option>
            ) : (
            <option value="0">Não há Especialidades disponíveis</option>
            )}
            {especialidades.map((especialidade, index) => {
            return (
                <option key={index} value={especialidade._id}>
                {especialidade.nome}
                </option>
            );
            })}
        </select>
    </div>
  )
}

export default SelectEspecialidade
