import React, { useState } from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai';
import AlterarBasico from './Forms/alterarBasico';
import AlterarEmail from './Forms/alterarEmail';
import AlterarSenha from './Forms/alterarSenha';
import './style.css'

const Settings = (props) => {
  const [mostrarBasico, setMostrarBasico] = useState(true);
  const [mostrarEmail, setMostrarEmail] = useState(false);
  const [mostrarSenha, setMostrarSenha] = useState(false);

  function handleBack (){
    setMostrarBasico(true);
    setMostrarEmail(false);
    setMostrarSenha(false);
  }
  
  return (
    <>
      
      <div className="config-container">

        <div className="config-title">
          {mostrarEmail || mostrarSenha ? <AiOutlineArrowLeft onClick={handleBack} size={20} className='button-back' title='Voltar'/> : <></>}
          <h2>Configurações do usuário</h2>
        </div>
      
        {mostrarBasico && (<AlterarBasico selectedTab={props.selectedTab} setSelectedTab={props.setSelectedTab} setMostrarEmail={setMostrarEmail} setMostrarSenha={setMostrarSenha} setMostrarBasico={setMostrarBasico}/>)}
        {mostrarEmail && (<AlterarEmail selectedTab={props.selectedTab} setSelectedTab={props.setSelectedTab} />)}
        {mostrarSenha && (<AlterarSenha selectedTab={props.selectedTab} setSelectedTab={props.setSelectedTab} />)}

      </div>
    </>
  )
}

export default Settings
