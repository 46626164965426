import React,{ useContext } from 'react'
import { AgendaContext } from '../../../../../contexts/agendaContext';
import { PrefeituraContext } from '../../../../../contexts/prefeituraContext';

const Header = (props) => {
  const { nomesDosMeses,diasSemana, diaSelecionado } = useContext(AgendaContext);
  const { acharEspecialidade, acharLocal, acharHorarioFuncionamento } = useContext(PrefeituraContext);

  const dataDiaSelecionado = new Date(diaSelecionado);
  const diaDataSelecionada = dataDiaSelecionado.getDate();
  const mesDataSelecionada = dataDiaSelecionado.getMonth();
  const mesDiaSelecionado = nomesDosMeses[mesDataSelecionada];

  const translateDiaSemana = (dataString) => {
    dataString = new Date(dataString);
    const diaSemana = diasSemana[dataString?.getDay()]?.nome;
    return diaSemana;
  };

  return (
    <>
          <div className="modal-calendario-header-data">
            <h2>
              {diaDataSelecionada}
              <span>{mesDiaSelecionado}</span>
            </h2>
            {translateDiaSemana(diaSelecionado)}
          </div>
          <div className="modal-calendario-header-info">
            <h1>{acharEspecialidade(props.informartions.especialidade)}</h1>
            <h5>{acharLocal(props.informartions.local)}</h5>
          </div>
          <div className="modal-calendario-header-horarios">
            <b>Horário de Funcionamento</b>

            <span>
              {acharHorarioFuncionamento(props.informartions.local, props.informartions.especialidade)?.inicio} - {acharHorarioFuncionamento(props.informartions.local, props.informartions.especialidade)?.fim}<br />
              {acharHorarioFuncionamento(props.informartions.local, props.informartions.especialidade)?.dias?.map((dia) => diasSemana[dia].nome).join(", ")}
            </span>

          </div>
    </>

  )
}

export default Header
