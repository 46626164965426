import React, { useContext } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { UserProvider, UserContext } from "../contexts/userContext";
import { PrefeituraProvider } from "../contexts/prefeituraContext";
import { AgendaProvider } from "../contexts/agendaContext";
import { UserConfigProvider } from "../contexts/userConfigContext";
import Alert from "../components/Alert/index";

export default function Authenticator({ children }) {
  const { signed } = useContext(UserContext);
  let location = useLocation();

  return (
    <>
      {signed === true ? (
        <>
          <UserProvider>
            <UserConfigProvider>
              <PrefeituraProvider>
                <AgendaProvider>
                  <Alert />
                  {children}
                </AgendaProvider>
              </PrefeituraProvider>
            </UserConfigProvider>
          </UserProvider>
        </>
      ) : (
        <>
          <Navigate to="/login" replace state={{ path: location.pathname }} />
        </>
      )}
    </>
  );
}
