import React,{useContext,useState,useEffect} from 'react'
import logo from '../../assets/images/cocaldosul_logo.png'
import { BiUser, BiCalendar } from 'react-icons/bi'
import { BsGear } from 'react-icons/bs'
import {UserContext} from '../../contexts/userContext';
import { PrefeituraContext } from '../../contexts/prefeituraContext';
import Profile from '../Profile/index';
import Agenda from '../Agenda/index';
import Settings from '../Configurações/index';
import './style.css'

const Home = () => {
  const { signOut } = useContext(UserContext);
  const { prefeitura } = useContext(PrefeituraContext);
  const [selectedTab, setSelectedTab] = useState('perfil');


  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  let componentToRender;

  if (selectedTab === 'perfil') {
    componentToRender = <Profile  />;
  } else if (selectedTab === 'agenda') {
    componentToRender = <Agenda />;
  } else if (selectedTab === 'configuracoes') {
    componentToRender = <Settings setSelectedTab={setSelectedTab}/>;
  }

  return (
    <div className='home-wrapper'>
      <div className='home-header'>
        <img className='logo-home' src={logo} alt='logomarca voxcity tecnologia' />
        <h2 className='prefeitura-name'>{prefeitura?.nome}</h2>
        <div className='home-tabs'>
          <div className={`tab ${selectedTab === 'perfil' ? 'selected' : ''}`} onClick={() => handleTabClick('perfil')} >
            <BiUser />
            <span>Perfil</span>
          </div>

          <div className={`tab ${selectedTab === 'agenda' ? 'selected' : ''}`} onClick={() => handleTabClick('agenda')} >
            <BiCalendar />
            <span>Agenda</span>
          </div>

          <div className={`tab ${selectedTab === 'configuracoes' ? 'selected' : ''}`} onClick={() => handleTabClick('configuracoes')}>
            <BsGear />
            <span>Configurações</span>
          </div>

        </div>
        <button className='logout' onClick={signOut}>
          Sair
        </button>
      </div>
      
      <div className='components-containder'>
        {componentToRender}
      </div>

    </div>
  );
};

export default Home;
