import React, { useState, useEffect } from "react";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";

export default function Arrows(props) {
    const { showConfirmarAgendamento, showDesmarcarAgendamento,prevDate,nextDate } = props;
    const [show, setShow] = useState(false);
    useEffect(() => {
        if (!showConfirmarAgendamento && !showDesmarcarAgendamento) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [showConfirmarAgendamento, showDesmarcarAgendamento]);
    return show ? <div className="button-container">
        <MdArrowBackIosNew
            id="previous"
            type="button"
            onClick={prevDate}
        />
        <MdArrowForwardIos
            id="next"
            type="button"
            onClick={nextDate}
        />
    </div> : null;
}
