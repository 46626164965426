import React, { useState } from 'react'
import  SelectAgendamento from './Actions/selectAgenda'
import Agendamento from './Actions/Calendario/index'
import Historico from './Actions/Historico/index'
import { AiOutlineArrowLeft } from 'react-icons/ai';
import './style.css'

const Agenda = () => {
  const [mostrarSelect, setMostrarSelect] = useState(true);
  const [mostrarAgendamento, setMostrarAgendamento] = useState(false);
  const [mostrarHistorico, setMostrarHistorico] = useState(true);


  function handleBack (){
    setMostrarSelect(true);
    setMostrarAgendamento(false);
  }

  return (
    <>
      <div className="agenda-container">
        <div className="config-title">
          {mostrarAgendamento ? <AiOutlineArrowLeft onClick={handleBack} size={20} className='button-back' title='Voltar'/> : <></>}
          <h2>Agendamento</h2>
        </div>

        {mostrarSelect && (<SelectAgendamento setMostrarAgendamento={setMostrarAgendamento} setMostrarSelect={setMostrarSelect}  />)}
        {mostrarAgendamento && (<Agendamento setMostrarHistorico={setMostrarHistorico} />)}
        {mostrarHistorico && (<Historico />)}
      </div>
    </>
  )
}

export default Agenda;
